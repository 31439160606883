<!--
// nuxt-ui/components/layout/header/featured-buttons/ButtonTickets.vue
-->
<script setup lang="ts">
import BaseNuxtLink from "~/nuxt-ui/components/base/baseNuxtLink.vue";
import TicketIcon from "~/nuxt-ui/components/Shared/icons/TicketIcon.vue";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";

const webappStore = useWebappStore();
const localePath = useLocalePath();

const ticketsData = computed(() => {
  if (webappStore.headerButtonsData?.tickets_button.tickets_button_option === "regionTickets") {
    return {
      url: localePath({
        name: "region-Tickets",
        params: { region: webappStore.activeRegion },
      }),
      target: "_self",
    };
  } else {
    return { url: webappStore.headerButtonsData?.tickets_button.tickets_button_link, target: "_blank" };
  }
});
</script>

<template>
  <base-nuxt-link
    v-if="
      webappStore.headerButtonsData?.tickets_button &&
      webappStore.headerButtonsData?.tickets_button.tickets_button_option !== 'hidden'
    "
    text-size="text-sm"
    full-width
    :to="ticketsData.url"
    :target="ticketsData.target"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <ticket-icon width="12"></ticket-icon>{{ $t("header.tickets") }}
    </span>
  </base-nuxt-link>
</template>

<style scoped></style>
