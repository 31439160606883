<!--
// nuxt-ui/components/base/ButtonLive.vue
-->
<script setup lang="ts">
import type { SocialMedia } from "~/src/region/domain/models/Region";
import TwitchIcon from "~/nuxt-ui/components/Shared/icons/TwitchIcon.vue";

import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";

const competitionStore = useCompetitionStore();
const webappStore = useWebappStore();
const { locale } = useI18n();
const { findRegionInfoBySlug } = regionUiService();

const socialMedia: SocialMedia[] = competitionStore.region?.social || [];
const regionTwitch: string | undefined = socialMedia.find(s => s.name === "twitch")?.url;
const seenRegion = ref<string | undefined>(competitionStore.region?.name);

onMounted(() => {
  seenRegion.value = findRegionInfoBySlug(webappStore.twitchInfo?.stream.region, "name");
});
</script>

<template>
  <a
    :href="
      webappStore.twitchInfo
        ? `https://www.twitch.tv/${webappStore.twitchInfo?.stream.account}`
        : regionTwitch || '#'
    "
    target="_blank"
    class="w-full min-w-max flex items-center justify-center btn btn-default relative text-sm"
  >
    <span class="flex gap-2 items-center">
      <span class="relative flex h-2 w-2" v-if="!!webappStore.twitchInfo?.live">
        <span
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"
        ></span>
        <span class="relative inline-flex rounded-full h-2 w-2 bg-red-600"></span>
      </span>
      <span class="flex items-center gap-1">
        <twitch-icon width="10"></twitch-icon>{{ $t("header.liveBtn") }}
      </span>
    </span>
  </a>
</template>

<style scoped></style>
