<!--
// nuxt-ui/components/layout/header/featured-buttons/ButtonStore.vue
-->
<script setup lang="ts">
import BaseNuxtLink from "~/nuxt-ui/components/base/baseNuxtLink.vue";
import ShirtIcon from "~/nuxt-ui/components/Shared/icons/ShirtIcon.vue";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";

const webappStore = useWebappStore();
const localePath = useLocalePath();

const storeData = computed(() => {
  if (webappStore.headerButtonsData?.store_button.store_button_option === "landingStores") {
    return {
      url: localePath({
        name: "StoresLanding",
      }),
      target: "_blank",
    };
  } else {
    return { url: webappStore.headerButtonsData?.store_button.store_button_link, target: "_blank" };
  }
});
</script>

<template>
  <base-nuxt-link
    v-if="
      webappStore.headerButtonsData?.store_button &&
      webappStore.headerButtonsData?.store_button.store_button_option !== 'hidden'
    "
    text-size="text-sm"
    full-width
    emphasis
    :to="storeData.url"
    :target="storeData.target"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <shirt-icon width="14"></shirt-icon>
      {{ $t("header.store") }}
    </span>
  </base-nuxt-link>
</template>

<style scoped></style>
