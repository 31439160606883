// src/options/infrastructure/ui-services/headerOptionsUiService.ts

import type { HeaderButtonsSetup } from "~/src/options/domain/models/CmsOptions";
import type { NitroFetchOptions } from "nitropack";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface HeaderOptionsUiService {
  getHeaderButtonsOptions: (regionId: number) => Promise<HeaderButtonsSetup | undefined>;
}

const headerOptionsUiService = (): HeaderOptionsUiService => ({
  getHeaderButtonsOptions,
});

export default headerOptionsUiService;

async function getHeaderButtonsOptions(regionId: number): Promise<HeaderButtonsSetup | undefined> {
  const { webContext } = useWebContext();
  if (!regionId) {
    console.error("Error on fetch header buttons data: Impossible to define regionId.");
    return undefined;
  }

  let path = `/api/cms/${regionId}/header-buttons`;
  const opts: NitroFetchOptions<any> = { query: { ctx: webContext } };

  try {
    const data = await $fetch<HeaderButtonsSetup | undefined>(path, opts);
    if (data) {
      return data;
    } else {
      return undefined;
    }
  } catch (e) {
    console.error("Error fetching banners home: ", e);
  }
}
